
.wrap{
  background-color: var(--dark-color) ;
  height: 100vh;

  * {
    color: var(--white-color);
    font-size: 16px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.334;
    letter-spacing: 0em;
    transition: 0.2s all;
  }
  h1{
    text-align: center;
    font-size: 1.5rem;
    border-top: 1px solid var(--red-color);
    border-bottom: 1px solid var(--red-color);
    padding: 8px 0;
  }

  .logo{
    display: flex;
    align-items: center;
    width: 300px;
    .imgWrap{
      img{
        width: 79px;
        height: 91px;
      }
    }
    .title{
      margin-left: 10px;
      margin-top: 5px;
      font-size: 14px;
      font-weight: 500;
    }
    @media (max-width: 400px) {
      width: 250px;
    }
  }

  .nav{
    >ul{
      padding: 16px;
      >li{
        margin-bottom: 4px;
      }
    }
    .title{
      display: flex;
      align-items: center;
      .icon{
        padding: 0 16px;
      }

    }
    :global(.ant-collapse-header), >ul>li>a{
      padding: 8px !important;
      width: 100%;
      display: inline-block;
      border-radius: 8px !important;
      &:hover{
        background-color: var(--dark-hover-color);
      }
      &.active{
        background-color: var(--dark-active-color);
      }

    }
    .subMenu{

      &,*{
        background-color: inherit;
        color: var(--white-color);
        border: 0;
        padding: 0;
        margin: 0;
      }

      a{
        display: inline-block;
        width: 100%;
        padding: 12px 8px 12px 56px;
        .count{
          display: inline-block;
          font-size: 14px;
          min-width: 36px;
          text-align: center;
          border: 2px solid var(--red-color);
          border-radius: 15px;
          padding: 2px 8px;
        }
        .text *{
          color: var(--disabled-color);
        }
        &.active{
          .text *{
            color: var(--white-color);
          }
        }
        @media (max-width: 400px) {
          padding: 12px 8px 12px 18px;
        }
      }
      ul{
        position: relative;
        .text{
          display: flex;
          justify-content: space-between;
          width: 100%;
        }
        &::after{
          position: absolute;
          content: "";
          height: 100%;
          width: 1px;
          background-color: rgb(224, 224, 224);
          opacity: 0.3;
          left: 36px;
          top: 0px;
        }
        @media (max-width: 400px) {
          &::after{
            display: none;
          }
        }
      }
    }
  }

  &.hideMenu{
    overflow: hidden;
    h1,.subMenu :global(.ant-collapse-arrow){
      opacity: 0;
    }
    .nav{
      >ul {
        padding-right: 2px;
        padding-left: 2px;
      }
      .icon{
        padding-left: 21px;
      }
      .text {
        opacity: 0;

      }
    }

  }
}


