:root {

  // colors
  --dark-color: #212d3a;
  --dark-active-color: #38424e;
  --dark-hover-color: #454f5a;
  --disabled-color: rgb(158, 158, 158);
  --blue-color: rgb(2, 136, 209);
  --blue-light-color: #d6ecf8;
  --pink-light-color: rgba(185, 32, 52, 0.16);
  --white-color: #ffffff;
  --bg-color-1: #f4f5fd;
  --black-color: #1F1F1C;
  --red-color: #b92034;
}



