
body {
  font-size: 1rem;
  font-family: "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  background-color: var(--bg-color-1) ;
  overflow: hidden;
}
p,h1,h2,h3,h4,h5,h6 {
  font-size: 0.875rem;
  font-weight: 500;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.334;
}
a{
  &:focus {
    outline: none;
  }
}
img{
  max-width: 100%;
  width: 100%;
}
.title {
  padding: 100px 0 120px;
  font-size: 42px;
  color: #171f28;
  text-align: center;
}
.error{
  color: red;
}
.ant-input-number{
  border: 0;
}
.my-input {
  position: relative;
  input,
  textarea
  {
    background: inherit;
    border: 1px solid var(--disabled-color);
    border-image: var(--disabled-color);
    border-image-slice: 1;
    padding: 16.5px 14px;
    border-radius: 10px;
    width: 100%;
    &:focus {
      outline: none;
      border: 1px solid var(--dark-hover-color);
      border-image: var(--dark-hover-color);
      border-image-slice: 1;
      box-shadow: 1px 1px 2px 0 var(--dark-hover-color);
    }
    &:hover{
      border-color: var(--dark-color);
    }
  }
  input:focus ~ label,
  textarea:focus ~ label,
  input:not([value=""]) ~ label,
  textarea:not(:empty) ~ label {
    top: -8px;
    display: block;
    background: var(--dark-color);
    color: var(--white-color);
    border-radius: 15px;
    padding: 2px 5px;
    left: 10px;
    opacity: 1;
    font-size: 12px;
  }

  label {
    pointer-events: none;
    position: absolute;
    top: 16px;
    left: 10px;
    color: var(--dark-color);
    cursor: text;
    transition: all 0.3s;
    opacity: 0.5;
  }
  &.error {
    input,
    textarea {
      border: 1px solid var(--red-color);
      border-image: var(--red-color);
      &:focus {
        border: 1px solid var(--red-color);
        border-image: var(--red-color);
        box-shadow: 1px 1px 2px 0 var(--red-color);
      }
    }
  }
}
.my-btn  {
  display: inline-flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  outline: currentcolor none 0px;
  border: 0px none;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 4px 10px;
  border-radius: 8px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(255, 255, 255);
  background-color: rgb(33, 45, 58);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  width: 100%;
  &:hover, &:focus, &:active{
    background-color: var(--dark-hover-color);
  }
}
.ant-select-selector{
  border-radius: 10px !important;
  border-color: var(--dark-hover-color) !important;
  &:hover, &:focus, &:active{
    border-color: var(--dark-color) !important;
  }
}
.ant-card-head-title{
  white-space: normal;
}
.ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane, .ant-tabs-left > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane{
  @media (max-width: 400px) {
    padding-left: 5px;
  }
}
.disabled-link {
  pointer-events: none;
}

