.eye {
  position: absolute;
  right: 10px;
  top: 17px;
  cursor: pointer;
  * {
    font-size: 20px;
  }
}
.login {
  display: flex;
  margin: 0 auto;
  align-items: center;
  padding: 0 10px;
  width: 100%;
  height: 100%;
  flex-direction: column;

  .title {
    margin: 0px;
    font-size: 1.5rem;
    font-weight: 600;
    color: rgb(35, 46, 70);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.2;
    letter-spacing: -0.00833em;
    text-align: center;
  }
  .container {
    max-width: 600px;
    width: 100%;
    background-color: rgb(255, 255, 255);
    color: rgb(35, 46, 70);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
      rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    padding: 16px;
  }
}
