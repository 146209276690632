.tabs{
  display: grid;
  width: 100%;
  padding-top: 15px;
  grid-template-columns: 250px 1fr;
  :global(.ant-tabs-nav) *{
    white-space: normal;
    text-align: left;
  }


  @media (max-width: 900px) {
    grid-template-columns: 100%;
    :global(.ant-tabs-nav) {
      border-bottom: 1px solid var(--dark-color);
      margin-bottom: 15px;
    }
    :global(.ant-tabs-nav-list){
      height: 0px;
    }
    //ant-tabs-tab ant-tabs-tab-active
  //  :global(.ant-tabs-nav-list){
  //    :global(div.ant-tabs-tab){
  //      transition: 0.5s all;
  //      opacity: 0;
  //      position: absolute;
  //      pointer-events: none;
  //    }
  //    :global(div.ant-tabs-tab.ant-tabs-tab-active){
  //      opacity: 1;
  //      pointer-events: auto;
  //      position: relative;
  //    }
  //  }
  //  :global(.ant-tabs-nav-list.targeted){
  //    :global(div.ant-tabs-tab){
  //      opacity: 1;
  //      position: relative;
  //      pointer-events: auto;
  //    }
  //  }
  }
}